@import '~@fontsource/noto-sans-jp/index.css';
@import '~@fontsource/noto-sans-jp/100.css';
@import '~@fontsource/noto-sans-jp/300.css';
@import '~@fontsource/noto-sans-jp/500.css';
@import '~@fontsource/noto-sans-jp/700.css';
@import '~@fontsource/noto-sans-jp/900.css';
@import '~antd/dist/antd.css';
@import './variables.scss';

html,
body {
  height: 100%;
  font-size: 16px;
  font-family:
    'Noto Sans JP',
    'Hiragino Kaku Gothic ProN',
    'ヒラギノ角ゴ ProN W3',
    Meiryo,
    メイリオ,
    Osaka,
    'MS PGothic',
    arial,
    helvetica,
    sans-serif;
  color: $venus-text-primary;
}

#root {
  width: 100%;
  height: 100%;
  min-width: 320px;
}

div[class^='ant-modal'] {
  // popupの中のfont-colorを全局レベルで対応する
  color: $venus-text-primary;

  @extend %venus-pc-regular;
}

a {
  color: $venus-color-primary;

  &:hover {
    color: rgba(0, 133, 175, 0.62);
  }
}

.ant-input {
  color: $venus-text-primary;
}

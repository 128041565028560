@import '../../../../assets/variables.scss';

.mustInputLabel {
  margin-left: 0.5rem;
}

.errorMsg {
  @extend %venus-pc-small;

  color: $venus-color-error;
  margin-top: 0.5rem;
}

.reactNodelabelWrapper {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

@media screen and (max-width: 768px) {
  .labelWrapper {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    font-weight: 400;
  }
}

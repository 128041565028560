@import '../../../../assets/variables';

.userEditFormWrapper {
  margin-left: calc((79% - 480px)/2);;
  div[class='ant-row']:not(:nth-last-child(1)) {
    margin-bottom: 2rem;
  }

  .itemContent {
    @extend %venus-pc-regular;
  }

  .editableItem {
    input[class='ant-input'] {
      height: unset;
      width: 480px;
    }
  }

  .hintWrapper {
    // margin-bottom: 2rem;
    // margin-top: -1.5rem;

    .hint {
      margin-top: 0.5rem;

      @extend %venus-pc-small;

      color: $venus-text-secondary;
    }
  }
}

@media screen and (max-width: 768px) {
  .userEditFormWrapper {
    margin-left:unset;
    div[class='ant-row']:not(:nth-last-child(0)) {
      margin-bottom: -1rem;
    }

    .itemContent {
      @extend %venus-pc-regular;

      margin: 0.5rem 0 1.5rem 0;
    }

    .editableItem {
      input[class='ant-input'] {
        margin: 0.5rem 0 1.5rem 0;
        width: 100%;
      }
    }

    select {
      margin: 0.5rem 0 1.5rem 0;
    }

    div[class='ant-row']:not(:nth-last-child(1)) {
      margin-bottom: unset;
    }

    .hintWrapper {
      margin: -1.5rem 0 1.5rem 0;
    }
  }
}

@import '../../../../assets/variables.scss';

.venusBtn {
  @extend %venus-pc-button;

  padding: 0 1rem;
  background: #fff;
  border-color: $venus-btn-border;
  border: 2px solid;
  border-radius: 4px;
  color: $venus-text-secondary;
  cursor: pointer;
  height: 32px;
  width: 132px;
  outline: none;
}

.venusBtn[disabled] {
  border-color: #d9d9d9;
  border: 1px solid;
  color: #d9d9d9;
  cursor: not-allowed;
  background: $venus-color-background;
}

.venustBtnBlock {
  display: block;
}

.venusBtnPrimary {
  border: unset;
  color: $venus-color-primary-background;
  background: $venus-color-primary;
}

.venusBtnDanger {
  border: unset;
  width: unset;
  color: $venus-color-background;
  background: $venus-color-error;
}

.venusBtnGradient {
  border: unset;
  color: $venus-color-primary-background;
  background: rgb(0, 133, 175);
  background:
    -moz-linear-gradient(
      -45deg,
      rgba(0, 133, 175, 1) 0%,
      rgba(0, 133, 175, 1) 34%,
      rgba(128, 208, 199, 1) 100%
    );
  background:
    -webkit-linear-gradient(
      -45deg,
      rgba(0, 133, 175, 1) 0%,
      rgba(0, 133, 175, 1) 34%,
      rgba(128, 208, 199, 1) 100%
    );
  background:
    linear-gradient(
      135deg,
      rgba(0, 133, 175, 1) 0%,
      rgba(0, 133, 175, 1) 34%,
      rgba(128, 208, 199, 1) 100%
    );
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#0085af', endColorstr='#80d0c7', GradientType=1);
}

.venusBtnGradientRed {
  border: unset;
  color: $venus-color-background;
  background: #ec0e19;
  background:
    -moz-linear-gradient(
      -45deg,
      #ec0e19 0%,
      #ec0e19 34%,
      #e79e9e 100%
    );
  background:
    -webkit-linear-gradient(
      -45deg,
      #ec0e19 0%,
      #ec0e19 34%,
      #e79e9e 100%
    );
  background: linear-gradient(135deg, #ec0e19 0%, #ec0e19 34%, #e79e9e 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ec0e19', endColorstr='#ffeddd', GradientType=1);
}

.venusBtnLink {
  border: unset;
  color: $venus-color-primary;
  background-color: unset;

  &:hover,
  &:focus,
  &::selection {
    background-color: $venus-color-hover;
    border: unset;
  }
}

.venusBtnText {
  height: 24px;
  line-height: 24px;
  width: unset;
  border: unset;
  color: $venus-color-primary;
  padding: 0 0.5rem;
  background-color: unset;

  &:hover,
  &:focus,
  &::selection {
    background-color: $venus-color-hover;
  }
}

@media screen and (max-width: 768px) {
  .venusBtn {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    height: 36px;
  }
}

@import '../../../../assets/variables';

.label {
  @extend %venus-pc-small;

  display: inline-block;
  text-align: center;
  height: 1.5rem;
  line-height: 1.5rem;
  color: #fff;
  border-radius: 0.25rem;
  white-space: nowrap;
}

.primary {
  background-color: $venus-color-primary;
}

.mustInput {
  background-color: $venus-color-secondary;
  padding: 0 0.25rem;
}

@media screen and (max-width: 768px) {
  .mustInput {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    height: 1rem;
  }
}

@import '../../../../assets/variables';

.commonHeaderWrapper {
  width: 100%;
  padding: 0 1rem;
  height: 50px;
  line-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  z-index: 3000;
  top: 0;
  background: #fff;
  box-shadow: 0 2px 8px 2px rgb(0 0 0 / 10%);

  .ant-dropdown-link {
    color: #fff;

    .anticon {
      margin-right: 10px;
    }
  }

  .sideLeft,
  .sideRight {
    width: 15rem;
  }

  .sideRight {
    text-align: right;
  }

  .sideLeft {
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .commonHeaderWrapper {
    .sideLeft,
    .sideRight {
      width: 2rem;
    }
  }
}

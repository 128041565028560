@import '../../../../assets/variables.scss';

.inputWrapper {
  input[class^='ant-input'] {
    @extend %venus-pc-regular;

    height: 36px;
    border-radius: 4px;
    padding: 4px 1rem;

    &:hover {
      border-color: $venus-color-primary;
    }

    &:focus {
      border-color: $venus-color-primary;
    }

    &::placeholder {
      color: $venus-text-secondary;
    }
  }
}

.errorState {
  input[class='ant-input'] {
    border: 1px solid $venus-color-error;
    box-shadow: 0 0 4px 2px #f2a7a9;

    &:hover {
      border-color: $venus-color-error;
    }

    &:focus {
      border-color: $venus-color-error;
    }
  }
}

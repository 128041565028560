@import '../../../assets/variables.scss';

.mainWrapper {
  display: flex;
  position: relative;
}

.fixedSideMenuWrapper {
  position: fixed;
  z-index: 999;
  min-height: 100vh;
  background-color: #fff;
  box-shadow: 0 2px 8px 2px rgb(0 0 0 / 10%);
  padding-top: 50px;
}

.commonLayoutWrapper {
  background-color: $venus-color-background;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  padding-top: 50px;
}

.sideMenuOpenPadding {
  padding-left: 256px;
}

.logoContainer {
  font-size: 0;
  cursor: pointer;

  img {
    height: 33px;
    width: 238px;
  }
}

.hamburgerbarContainer {
  button[class^='ant-btn'] {
    color: $venus-color-primary;
    border: 0;
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 50%;
    text-align: center;

    &:hover {
      background-color: $venus-color-hover;
    }

    svg {
      fill: $venus-color-primary;
      height: 1.5rem;
      width: 1.5rem;
      vertical-align: middle;
    }
  }
}

.userAvatarPcWrapperLine2 {
  display: inline-block;
  height: 48px;

  .userNameWrapper {
    display: inline-block;
  }

  .userName {
    @extend %venus-pc-bold;
    @extend %rowOmit;

    -webkit-line-clamp: 2;
    word-break: break-all;
  }
}

.userAvatarPcWrapper {
  .userName {
    @extend %venus-pc-bold;
  }
}

.userAvatarMobileWrapper {
  @extend .hamburgerbarContainer;
}

.userInfoDropDownWrapper {
  z-index: 2000;

  ul {
    padding: 0.5rem 0;
    border-radius: 0.25rem;

    li[class^='ant-dropdown-menu-item'] {
      @extend %venus-pc-bold;

      padding: 0 1rem;
      height: 2.25rem;
      line-height: 2.25rem;

      > a {
        color: $venus-text-primary;
        margin: unset;
        padding: unset;
      }

      &:hover {
        background-color: $venus-color-hover;
      }
    }
  }
}

.logoutModalWrapper {
  .logoutModalBody {
    min-height: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .logoContainer {
    font-size: 0;
    cursor: pointer;

    img {
      height: 26px;
      width: 186px;
    }
  }
}

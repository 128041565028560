$red: #122222;
$sg-blue60: #407298;
$sg-error-red: #ec0e19;

$venus-color-primary: #0085af;
$venus-color-secondary: #e4582e;
$venus-color-error: #ec0e19;
$venus-color-background: #f3f3f3;
$venus-color-primary-background: #fff;
$venus-text-primary: rgba(19, 23, 26, 0.8);
$venus-text-secondary: rgba(19, 23, 26, 0.62);
$venus-color-divider: rgba(64, 67, 70, 0.14);
$venus-color-text: #d9d9d9;
$venus-color-hover: rgba(0, 133, 175, 0.1);
$venus-btn-border: rgba(19, 23, 26, 0.62);
$venus-checkbox-disabled: rgba(64, 67, 70, 0.14);
$venus-color-oblique: rgba(19, 23, 26, 0.23);

$venus-mobile-modal-body-height: 23rem;
$venus-pc-modal-body-height: 35rem;

%venus-pc-title1 {
  font-size: 1.25rem;
  line-height: 1.6875rem;
  font-weight: 700;
}

%venus-pc-title2 {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  font-weight: 700;
}

%venus-pc-bold {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 700;
}

%venus-pc-regular {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 400;
}

%venus-pc-small {
  font-size: 0.875rem;
  line-height: 1.1875rem;
  font-weight: 400;
}

%venus-pc-micro {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
}

%venus-pc-button {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 700;
}

%venus-mobile-title1 {
  font-size: 1.25rem;
  line-height: 1.6875rem;
  font-weight: 700;
}

%venus-mobile-title2 {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  font-weight: 700;
}

%venus-mobile-bold {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 700;
}

%venus-mobile-regular {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 400;
}

%venus-mobile-small {
  font-size: 0.875rem;
  line-height: 1.1875rem;
  font-weight: 400;
}

%venus-mobile-micro {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
}

%rowOmit {
  display: -webkit-box;

  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

%contentWrapper {
  max-width: 1110px;
  min-width: 1110px;
  width: 1110px;
  margin-left: auto;
  margin-right: auto;
  padding: 2.25rem 4rem 3rem 4rem;
  background-color: $venus-color-background;
}

%itemBackgroundHover {
  background-color: #e6f7ff;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.12);
}

@import '../../../../assets/variables';

.modalWrapper {
  div[class='ant-modal-body'] {
    padding: 2.5rem 2.5rem 0 2.5rem;
  }

  button[class='ant-modal-close'] {
    display: none;
  }

  .modalBody {
    min-height: 13.5rem;

    .contentWrapper {
      .graphics {
        text-align: center;
      }

      .messageArea {
        padding: 2rem 0;

        .title {
          @extend %venus-pc-title1;
        }

        .subTitle {
          @extend %venus-pc-regular;

          margin-top: 1rem;
        }
      }
    }
  }

  div[class='ant-modal-footer'] {
    padding: 0 2.5rem 2.5rem 2.5rem;
    border-top: unset;
  }

  .btnWrapper {
    text-align: center;

    .cancelBtn {
      margin-right: 2.5rem;
    }
  }
}

.bodyMinHeight {
  min-height: 13.5rem;
}

@media screen and (max-width: 768px) {
  .modalWrapper {
    div[class='ant-modal-body'] {
      padding: 1rem 1rem 0 1rem;
    }

    div[class='ant-modal-footer'] {
      padding: 0 1rem 1rem 1rem;
      border-top: unset;
    }

    .btnWrapper {
      text-align: center;

      .cancelBtn {
        margin-right: unset;
        margin-left: unset;
        width: 100%;
      }

      .confirmBtn {
        margin-left: unset;
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }
  }
}

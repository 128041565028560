@import '../../../../assets/variables.scss';

.loadingWrapper {
  position: absolute;
  top: 50%;

  div[class='ant-spin ant-spin-spinning'] {
    color: $venus-color-primary;
  }
}

.positionCenter {
  left: calc(50% - 28px);
}

.positionInRightPart {
  right: calc(50% - 100px);
}

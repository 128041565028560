@import '../../../../assets/variables.scss';

.errorPageWrapper {
  height: 100%;
  background-color: $venus-color-background;

  .errorPageContent {
    max-width: 416px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%, -50%);
    transform: translate(-50%, -50%);

    .errorAni {
      width: 130px;
      height: 130px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .title {
      @extend %venus-pc-title1;

      margin-top: 2rem;
    }

    .detail {
      @extend %venus-pc-regular;

      margin-top: 2rem;
    }

    .description {
      margin-top: 1rem;
    }

    .returnToTopWrapper {
      margin-top: 1rem;

      .btnWrapper {
        text-align: center;
        margin-top: 2rem;
      }
    }

    .userLink {
      @extend %venus-pc-button;

      display: block;
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .errorPageWrapper {
    .errorPageContent {
      width: 100%;
      padding: 0 1rem;
    }
  }
}

@import '../../../../assets/variables.scss';

.contentWrapper {
  max-width: 1110px;
  min-width: 1110px;
  width: 1110px;
  margin-left: auto;
  margin-right: auto;
  padding: 2.25rem 4rem 3rem 4rem;
  background-color: $venus-color-background;

  .pageTitle {
    @extend %venus-pc-title1;

    text-align: center;
  }

  .btnWrapper {
    margin-top: 2.25rem;
    text-align: center;

    .secondaryBtn:not(:last-child) {
      margin-right: 3rem;
    }

    .gobackBtnWrapper {
      text-align: center;
      margin-top: 1.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .contentWrapper {
    max-width: unset;
    min-width: unset;
    width: 100%;
    padding: 1rem;

    .btnWrapperNoFix {
      position: unset !important;
      padding: 2.25rem 0 !important;
      background-color: unset !important;
    }

    .btnWrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: #fff;
      z-index: 1000;
      width: 100%;
      margin-top: unset;
      padding: 1.5rem 1rem;

      .secondaryBtn:not(:first-child) {
        margin-right: unset;
        margin-top: 0.5rem;
      }

      .secondaryBtn:not(:last-child) {
        margin-right: unset;
      }

      .gobackBtnWrapper {
        margin-top: 1rem;
      }
    }
  }
}

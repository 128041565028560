@import '../../../../assets/variables.scss';

.contentWrapper {
  position: absolute;
  min-width: 300px;
  top: 52px;
  right: 0;

  .message {
    @extend %venus-pc-bold;

    background: $venus-color-primary;
    padding: 0.5rem 1.5rem 0.5rem 0;
    color: #fff;
    border-radius: 4px;
  }

  .checkIcon {
    width: 1.5rem;
    height: 1.5rem;
    margin: -0.2rem 1.5rem 0 1.5rem;
    fill: white;
  }

  img {
    fill: white;
  }
}

@media screen and (max-width: 768px) {
  .contentWrapper {
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    right: unset;

    .checkIcon {
      width: 1rem;
      height: 1rem;
      margin: -0.125rem 1rem 0 1rem;
    }

    .message {
      font-size: 0.875rem;
      line-height: 1.1875rem;
      font-weight: 400;
      padding: 0.5rem 1rem 0.5rem 0;
    }
  }
}

@import '../../../../assets/variables.scss';

.venusModalWrapper {
  color: $venus-color-primary;

  div[class='ant-modal-content'] {
    border-radius: 0.25rem;
  }

  button[class='ant-modal-close'] {
    z-index: 1001;
    border: unset;
    top: -1rem;
    right: -1rem;

    svg {
      height: 2rem;
      width: 2rem;
    }

    span[class='ant-modal-close-x'] {
      height: 2rem;
      width: 2rem;
      line-height: 2rem;
      font-size: 0;
    }
  }
}

.venusFullScreenModalWrapper {
  div[class='ant-modal'] {
    padding-bottom: 0;
    max-width: 100%;

    div[class='ant-modal-content'] {
      border-radius: unset;
      height: 100%;
      margin: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .venusModalWrapper {
    > div[class^='ant-modal'] {
      padding-bottom: unset;
    }
  }
}

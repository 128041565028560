@import '../../../../assets/variables';

.sideMenuCollapsedWrapper {
  width: 0;
  min-width: 0;
  max-width: 0;

  ul[class*='ant-menu-inline-collapsed'] {
    width: 0;
  }
}

.itemWrapper {
  line-height: 1;

  .itemTitleIcon {
    margin-right: 0.5rem;
    vertical-align: middle;
    display: inline-block;

    > svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: $venus-text-primary;
    }
  }

  .itemTitleContent {
    @extend %venus-pc-title2;

    color: $venus-text-primary;
    vertical-align: middle;
  }
}

.itemContent {
  @extend %venus-pc-regular;

  color: $venus-text-primary;
  padding-left: 0.5rem;
}

.sideMenuWrapper {
  width: 256px;
  background-color: #fff;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  li[class^='ant-menu-item'] {
    width: 100%;
    height: 3rem;
    line-height: 3rem;
    margin-top: unset;
    margin-bottom: unset;
  }

  li[class~='ant-menu-item-selected'],
  li[class~='ant-menu-item-active'] {
    .itemTitleIcon {
      > svg {
        fill: $venus-color-primary;
      }
    }

    .itemTitleContent {
      color: $venus-color-primary;
    }

    .itemContent {
      color: $venus-color-primary;
    }
  }

  li[class^='ant-menu-item']::after {
    border-right: unset;
  }

  ul[class='ant-menu ant-menu-sub ant-menu-inline'] {
    border-right: unset;

    li[class^='ant-menu-item'] {
      width: 100%;
      height: 3rem;
      line-height: 3rem;
      margin-top: unset;
      margin-bottom: unset;
    }
  }

  ul[class='ant-menu ant-menu-light ant-menu-root ant-menu-inline'] {
    border-right: unset;

    li[class^='ant-menu-item']:not(:last-child) {
      margin-bottom: unset;
    }
  }
}

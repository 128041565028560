@import '../../../../assets/variables';

.venusSelectWrapper {
  color: $venus-text-primary;

  div[class^='ant-select'] {
    div[class='ant-select-selector'] {
      @extend %venus-pc-regular;

      border-radius: 4px;
      padding: 0 1rem;
      height: 36px;

      span[class='ant-select-selection-item'],
      span[class='ant-select-selection-placeholder'] {
        height: 36px;
        line-height: 36px;
      }
    }

    span[class='ant-select-arrow'] {
      background-image: url(../../../../assets/images/angle-down-primary.svg);
      background-size: 1rem;
      width: 1rem;
      height: 1rem;
      right: 1rem;
      top: 48%;

      > span {
        display: none;
      }
    }
  }

  div[class~='ant-select-open'] {
    span[class='ant-select-arrow'] {
      background-image: url(../../../../assets/images/angle-up-primary.svg);
      background-size: 1rem;
      width: 1rem;
      height: 1rem;
      right: 1rem;
      top: 48%;

      > span {
        display: none;
      }
    }
  }

  div[class^='ant-select']:not(div[class~='ant-select-disabled']) {
    &:hover {
      div[class='ant-select-selector'] {
        border-color: $venus-color-primary;
      }
    }

    div[class='ant-select-selector'] {
      span[class='ant-select-selection-item'],
      span[class='ant-select-selection-placeholder'] {
        color: $venus-text-primary;
      }
    }
  }

  div[class~='ant-select-disabled'] {
    span[class='ant-select-arrow'] {
      background-image: url(../../../../assets/images/angle-down-disabled.svg);
      background-size: 1rem;
      width: 1rem;
      height: 1rem;
      right: 1rem;
      top: 48%;

      > span {
        display: none;
      }
    }
  }

  div[class^='ant-select ant-select-focused']:not(div[class~='ant-select-disabled']):not(.ant-select-customize-input) {
    div[class='ant-select-selector'] {
      box-shadow: 0 0 0 2px rgba(0, 133, 175, 0.1);
      border-color: $venus-color-primary;
    }
  }
}

.venusSelectDropdown {
  div[class='ant-select-item-option-content'] {
    color: $venus-text-primary;
  }
}

.mobileSelectedValue {
  @extend %venus-pc-regular;

  background-image: url(../../../../assets/images/angle-down-primary.svg);
  background-repeat: no-repeat;
  background-position: right 1rem top 0.625rem;
  background-size: 1rem 1rem;
  appearance: none;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 0 2.5rem 0 1rem;
  height: 36px;
  text-align: left;
  background-color: #fff;

  &:focus {
    border-color: $venus-color-primary;
    box-shadow: none;
    outline: none;
  }

  &::selection {
    background: unset;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    background-image: url(../../../../assets/images/angle-down-disabled.svg);
  }

  .arrowIcon {
    position: relative;
    float: right;
    right: 0;
    fill: $venus-color-primary;
    background-size: 1rem;
    width: 1rem;
    height: 1rem;
    top: 25%;
  }
}

.mobileErrorStatus {
  border: 1px solid $venus-color-error;
  border-radius: 4px;
  box-shadow: 0 0 4px 2px #f2a7a9;

  &:focus {
    border-color: $venus-color-error;
    box-shadow: 0 0 4px 2px #f2a7a9;
  }
}

.pcErrorStatus {
  > div[class^='ant-select'] {
    div[class='ant-select-selector'] {
      @extend .mobileErrorStatus;
    }
  }

  div[class~='ant-select']:not(.ant-select-disabled):hover {
    div[class='ant-select-selector'] {
      border-color: $venus-color-error;
    }
  }

  div[class^='ant-select ant-select-focused']:not(.ant-select-disabled):not(.ant-select-customize-input) {
    div[class='ant-select-selector'] {
      border-color: $venus-color-error;
      box-shadow: 0 0 4px 2px #f2a7a9;
    }
  }
}
